<template>
  <div class="container">
    <PublicHeader
      :totalName="$t('with').title"
      routerName="Home"
      :rightTitle="$t('with').history"
      rightRouter="WithHistory"
    />
    <div class="content">
      <van-cell
        is-link
        :title="item.name"
        v-for="item in list"
        :key="item.name"
        @click="toForm(item.name)"
      >
        <template #icon>
          <img :src="item.img" alt="" />
        </template>
      </van-cell>
      <van-cell is-link :title="$t('msgbank').bankCard"  @click="$router.push({ name: 'WithFormBank' })" v-if="basicConfig.IsBank">
        <template #icon>
          <img src="@/assets/new/bankcard.png" alt="" />
        </template>
      </van-cell>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PublicHeader from '@/components/publicHeaderNew'
import userApi from '@/api/user'
export default {
  components: {
    PublicHeader
  },
  computed: {
    ...mapGetters(['basicConfig', 'lang', 'connection'])
  },
  data() {
    return {
      list: [
        {
          img: require('@/assets/new/icon2.png'),
          name: 'USDT'
        },
        {
          img: require('@/assets/new/icon0.png'),
          name: 'ETH'
        },
        {
          img: require('@/assets/new/icon1.png'),
          name: 'BTC'
        }
      ]
    }
  },
  methods: {
    async goServe() {
      let str = this.basicConfig.ServiceUrl
      const res = await this.getUserInfo()
      let userInfo = {
        name: res.UserName,
        uid: res.Code
      }
      if (str.indexOf('?') === -1) {
        str += '?userInfo=' + JSON.stringify(userInfo)
      } else {
        str += '&userInfo=' + JSON.stringify(userInfo)
      }
      str += `&lang=${this.lang === 'zh' ? 'zh-CN' : 'en-US'}`
      location.href = str
    },
    async getUserInfo() {
      const userInfo = await userApi.userInfo()
      return userInfo
    },
    toForm(type) {
      this.$router.push({
        name: 'WithForm',
        query: {
          type
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  padding-top: 44px;
  .van-cell {
    img {
      width: 25px;
      height: 26px;
      margin-right: 10px;
    }
  }
  .content {
    background: #fff;
    margin: 15px 15px 30px;
    border-radius: 5px;
    overflow: hidden;
  }
}
</style>
